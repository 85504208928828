import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _56bc6f56 = () => interopDefault(import('../pages/accessibilite.vue' /* webpackChunkName: "pages/accessibilite" */))
const _6afa37af = () => interopDefault(import('../pages/bureau-etude-urbanisme.vue' /* webpackChunkName: "pages/bureau-etude-urbanisme" */))
const _b69ba2bc = () => interopDefault(import('../pages/collectivites-territoriales.vue' /* webpackChunkName: "pages/collectivites-territoriales" */))
const _cc34f9a4 = () => interopDefault(import('../pages/confidentialite.vue' /* webpackChunkName: "pages/confidentialite" */))
const _c598628a = () => interopDefault(import('../pages/ddt-ddtm-dreal.vue' /* webpackChunkName: "pages/ddt-ddtm-dreal" */))
const _53ad8c4a = () => interopDefault(import('../pages/exports.vue' /* webpackChunkName: "pages/exports" */))
const _4f821935 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _5b7efc18 = () => interopDefault(import('../pages/guide.vue' /* webpackChunkName: "pages/guide" */))
const _27723e70 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _78a7471d = () => interopDefault(import('../pages/loi-climat-et-resilience.vue' /* webpackChunkName: "pages/loi-climat-et-resilience" */))
const _2b319137 = () => interopDefault(import('../pages/mentions-legales.vue' /* webpackChunkName: "pages/mentions-legales" */))
const _5c911b45 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _70a32a7e = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _4cc25c8d = () => interopDefault(import('../pages/validation.vue' /* webpackChunkName: "pages/validation" */))
const _1676473c = () => interopDefault(import('../pages/dev/api.vue' /* webpackChunkName: "pages/dev/api" */))
const _2470cebd = () => interopDefault(import('../pages/stats/state.vue' /* webpackChunkName: "pages/stats/state" */))
const _17d1dcc0 = () => interopDefault(import('../pages/stats/urba.vue' /* webpackChunkName: "pages/stats/urba" */))
const _54f39e4e = () => interopDefault(import('../pages/login/collectivites/explain.vue' /* webpackChunkName: "pages/login/collectivites/explain" */))
const _095d79ca = () => interopDefault(import('../pages/login/collectivites/signin.vue' /* webpackChunkName: "pages/login/collectivites/signin" */))
const _0fe79991 = () => interopDefault(import('../pages/login/collectivites/signup.vue' /* webpackChunkName: "pages/login/collectivites/signup" */))
const _1c92935c = () => interopDefault(import('../pages/login/ddt/explain.vue' /* webpackChunkName: "pages/login/ddt/explain" */))
const _b4edcad2 = () => interopDefault(import('../pages/login/ddt/signin.vue' /* webpackChunkName: "pages/login/ddt/signin" */))
const _8bc11de6 = () => interopDefault(import('../pages/login/ddt/signup.vue' /* webpackChunkName: "pages/login/ddt/signup" */))
const _b1bde224 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _698db10c = () => interopDefault(import('../pages/collectivites/_collectiviteId.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId" */))
const _1904aa7d = () => interopDefault(import('../pages/collectivites/_collectiviteId/index.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/index" */))
const _4f327b5a = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees" */))
const _549dab96 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/data.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/data" */))
const _15eae371 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/geobretagne.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/geobretagne" */))
const _669d5db6 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/geoide.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/geoide" */))
const _fd0e882e = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/georisques.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/georisques" */))
const _2e9b0470 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/gpu.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/gpu" */))
const _05985d4f = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/inpn.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/inpn" */))
const _46bc9610 = () => interopDefault(import('../pages/collectivites/_collectiviteId/glossaire.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/glossaire" */))
const _894b8a06 = () => interopDefault(import('../pages/collectivites/_collectiviteId/pac.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/pac" */))
const _73e44647 = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/index.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/index" */))
const _33dae17d = () => interopDefault(import('../pages/collectivites/_collectiviteId/ressources.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/ressources" */))
const _13d169d6 = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/add.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/add" */))
const _7f69a11a = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/signup.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/signup" */))
const _b1f63612 = () => interopDefault(import('../pages/collectivites/_collectiviteId/procedures/add.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/procedures/add" */))
const _5ee6725f = () => interopDefault(import('../pages/ddt/_departement.vue' /* webpackChunkName: "pages/ddt/_departement" */))
const _560575a2 = () => interopDefault(import('../pages/ddt/_departement/index.vue' /* webpackChunkName: "pages/ddt/_departement/index" */))
const _0fa72fc3 = () => interopDefault(import('../pages/ddt/_departement/collectivites/index.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/index" */))
const _6024e93c = () => interopDefault(import('../pages/ddt/_departement/pac.vue' /* webpackChunkName: "pages/ddt/_departement/pac" */))
const _b4168e3a = () => interopDefault(import('../pages/ddt/_departement/procedures/index.vue' /* webpackChunkName: "pages/ddt/_departement/procedures/index" */))
const _043d81cc = () => interopDefault(import('../pages/ddt/_departement/collectivites/enquete.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/enquete" */))
const _cc003f04 = () => interopDefault(import('../pages/ddt/_departement/procedures/add-choose-collectivite.vue' /* webpackChunkName: "pages/ddt/_departement/procedures/add-choose-collectivite" */))
const _33d312ff = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/commune.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/commune" */))
const _f17b287c = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/epci.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/epci" */))
const _3ab09778 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/procedure/add.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/procedure/add" */))
const _06bc5fb2 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/dgd.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/dgd" */))
const _43e24e94 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/infos.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/infos" */))
const _01a13d58 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/perimetre.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/perimetre" */))
const _cb6da774 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/volet_qualitatif.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/_procedureId/volet_qualitatif" */))
const _75c4adb0 = () => interopDefault(import('../pages/faq/_folder.vue' /* webpackChunkName: "pages/faq/_folder" */))
const _0dbab4c6 = () => interopDefault(import('../pages/frise/_procedureId/index.vue' /* webpackChunkName: "pages/frise/_procedureId/index" */))
const _fecab56a = () => interopDefault(import('../pages/print/_projectId.vue' /* webpackChunkName: "pages/print/_projectId" */))
const _2ae1b264 = () => interopDefault(import('../pages/trames/_githubRef.vue' /* webpackChunkName: "pages/trames/_githubRef" */))
const _3d1f47ff = () => interopDefault(import('../pages/documents/_githubRef/pac.vue' /* webpackChunkName: "pages/documents/_githubRef/pac" */))
const _6dc127d6 = () => interopDefault(import('../pages/frise/_procedureId/add.vue' /* webpackChunkName: "pages/frise/_procedureId/add" */))
const _71c62376 = () => interopDefault(import('../pages/frise/_procedureId/invite.vue' /* webpackChunkName: "pages/frise/_procedureId/invite" */))
const _70b99bd2 = () => interopDefault(import('../pages/frise/_procedureId/_eventId.vue' /* webpackChunkName: "pages/frise/_procedureId/_eventId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessibilite",
    component: _56bc6f56,
    name: "accessibilite"
  }, {
    path: "/bureau-etude-urbanisme",
    component: _6afa37af,
    name: "bureau-etude-urbanisme"
  }, {
    path: "/collectivites-territoriales",
    component: _b69ba2bc,
    name: "collectivites-territoriales"
  }, {
    path: "/confidentialite",
    component: _cc34f9a4,
    name: "confidentialite"
  }, {
    path: "/ddt-ddtm-dreal",
    component: _c598628a,
    name: "ddt-ddtm-dreal"
  }, {
    path: "/exports",
    component: _53ad8c4a,
    name: "exports"
  }, {
    path: "/faq",
    component: _4f821935,
    name: "faq"
  }, {
    path: "/guide",
    component: _5b7efc18,
    name: "guide"
  }, {
    path: "/login",
    component: _27723e70,
    name: "login"
  }, {
    path: "/loi-climat-et-resilience",
    component: _78a7471d,
    name: "loi-climat-et-resilience"
  }, {
    path: "/mentions-legales",
    component: _2b319137,
    name: "mentions-legales"
  }, {
    path: "/profile",
    component: _5c911b45,
    name: "profile"
  }, {
    path: "/stats",
    component: _70a32a7e,
    name: "stats"
  }, {
    path: "/validation",
    component: _4cc25c8d,
    name: "validation"
  }, {
    path: "/dev/api",
    component: _1676473c,
    name: "dev-api"
  }, {
    path: "/stats/state",
    component: _2470cebd,
    name: "stats-state"
  }, {
    path: "/stats/urba",
    component: _17d1dcc0,
    name: "stats-urba"
  }, {
    path: "/login/collectivites/explain",
    component: _54f39e4e,
    name: "login-collectivites-explain"
  }, {
    path: "/login/collectivites/signin",
    component: _095d79ca,
    name: "login-collectivites-signin"
  }, {
    path: "/login/collectivites/signup",
    component: _0fe79991,
    name: "login-collectivites-signup"
  }, {
    path: "/login/ddt/explain",
    component: _1c92935c,
    name: "login-ddt-explain"
  }, {
    path: "/login/ddt/signin",
    component: _b4edcad2,
    name: "login-ddt-signin"
  }, {
    path: "/login/ddt/signup",
    component: _8bc11de6,
    name: "login-ddt-signup"
  }, {
    path: "/",
    component: _b1bde224,
    name: "index"
  }, {
    path: "/collectivites/:collectiviteId?",
    component: _698db10c,
    children: [{
      path: "",
      component: _1904aa7d,
      name: "collectivites-collectiviteId"
    }, {
      path: "donnees",
      component: _4f327b5a,
      name: "collectivites-collectiviteId-donnees",
      children: [{
        path: "data",
        component: _549dab96,
        name: "collectivites-collectiviteId-donnees-data"
      }, {
        path: "geobretagne",
        component: _15eae371,
        name: "collectivites-collectiviteId-donnees-geobretagne"
      }, {
        path: "geoide",
        component: _669d5db6,
        name: "collectivites-collectiviteId-donnees-geoide"
      }, {
        path: "georisques",
        component: _fd0e882e,
        name: "collectivites-collectiviteId-donnees-georisques"
      }, {
        path: "gpu",
        component: _2e9b0470,
        name: "collectivites-collectiviteId-donnees-gpu"
      }, {
        path: "inpn",
        component: _05985d4f,
        name: "collectivites-collectiviteId-donnees-inpn"
      }]
    }, {
      path: "glossaire",
      component: _46bc9610,
      name: "collectivites-collectiviteId-glossaire"
    }, {
      path: "pac",
      component: _894b8a06,
      name: "collectivites-collectiviteId-pac"
    }, {
      path: "prescriptions",
      component: _73e44647,
      name: "collectivites-collectiviteId-prescriptions"
    }, {
      path: "ressources",
      component: _33dae17d,
      name: "collectivites-collectiviteId-ressources"
    }, {
      path: "prescriptions/add",
      component: _13d169d6,
      name: "collectivites-collectiviteId-prescriptions-add"
    }, {
      path: "prescriptions/signup",
      component: _7f69a11a,
      name: "collectivites-collectiviteId-prescriptions-signup"
    }, {
      path: "procedures/add",
      component: _b1f63612,
      name: "collectivites-collectiviteId-procedures-add"
    }]
  }, {
    path: "/ddt/:departement?",
    component: _5ee6725f,
    children: [{
      path: "",
      component: _560575a2,
      name: "ddt-departement"
    }, {
      path: "collectivites",
      component: _0fa72fc3,
      name: "ddt-departement-collectivites"
    }, {
      path: "pac",
      component: _6024e93c,
      name: "ddt-departement-pac"
    }, {
      path: "procedures",
      component: _b4168e3a,
      name: "ddt-departement-procedures"
    }, {
      path: "collectivites/enquete",
      component: _043d81cc,
      name: "ddt-departement-collectivites-enquete"
    }, {
      path: "procedures/add-choose-collectivite",
      component: _cc003f04,
      name: "ddt-departement-procedures-add-choose-collectivite"
    }, {
      path: "collectivites/:collectiviteId/commune",
      component: _33d312ff,
      name: "ddt-departement-collectivites-collectiviteId-commune"
    }, {
      path: "collectivites/:collectiviteId/epci",
      component: _f17b287c,
      name: "ddt-departement-collectivites-collectiviteId-epci"
    }, {
      path: "collectivites/:collectiviteId/procedure/add",
      component: _3ab09778,
      name: "ddt-departement-collectivites-collectiviteId-procedure-add"
    }, {
      path: "collectivites/:collectiviteId/:procedureId?/dgd",
      component: _06bc5fb2,
      name: "ddt-departement-collectivites-collectiviteId-procedureId-dgd"
    }, {
      path: "collectivites/:collectiviteId/:procedureId?/infos",
      component: _43e24e94,
      name: "ddt-departement-collectivites-collectiviteId-procedureId-infos"
    }, {
      path: "collectivites/:collectiviteId/:procedureId?/perimetre",
      component: _01a13d58,
      name: "ddt-departement-collectivites-collectiviteId-procedureId-perimetre"
    }, {
      path: "collectivites/:collectiviteId/:procedureId?/volet_qualitatif",
      component: _cb6da774,
      name: "ddt-departement-collectivites-collectiviteId-procedureId-volet_qualitatif"
    }]
  }, {
    path: "/faq/:folder",
    component: _75c4adb0,
    name: "faq-folder"
  }, {
    path: "/frise/:procedureId",
    component: _0dbab4c6,
    name: "frise-procedureId"
  }, {
    path: "/print/:projectId?",
    component: _fecab56a,
    name: "print-projectId"
  }, {
    path: "/trames/:githubRef?",
    component: _2ae1b264,
    name: "trames-githubRef"
  }, {
    path: "/documents/:githubRef?/pac",
    component: _3d1f47ff,
    name: "documents-githubRef-pac"
  }, {
    path: "/frise/:procedureId?/add",
    component: _6dc127d6,
    name: "frise-procedureId-add"
  }, {
    path: "/frise/:procedureId?/invite",
    component: _71c62376,
    name: "frise-procedureId-invite"
  }, {
    path: "/frise/:procedureId?/:eventId",
    component: _70b99bd2,
    name: "frise-procedureId-eventId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
